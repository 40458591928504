import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Articles from "../components/articles"

export default function Tag({ data, pageContext }) {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">#{pageContext.tag}</h2>
      <hr className="my-8" />
      <Articles mdxNodes={data.allMdx.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query ($tag: String) {
    allMdx(
      filter: {frontmatter: {tags: {in: [$tag]}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "yyyy.MM.DD")
          slug
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        id
        excerpt
      }
    }
  }
`